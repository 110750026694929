<template>
    <div class="vx-col w-5/6 ml-auto mr-auto">
        <vx-card title="Edit Allocation Rule">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <span>Company</span>
                    <vs-input 
                        class="w-full" 
                        id="companyName" 
                        name="companyName" 
                        v-model="companyName"
                        :disabled="true"/>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <div class="allocation-container">
                        <div class="allocation-list">
                            <span class="list-title">Allocation Rule Master</span>
                            <draggable 
                                class="left-list" 
                                animation="150" 
                                v-model="localListAllocation" 
                                group="allocationRule"
                            >
                                <div 
                                    v-for="(option, index) in localListAllocation" 
                                    :key="index"
                                    class="item"
                                    @dragstart="dragStartHandler(option)"
                                    @dragend="dragEndHandler"
                                >{{ option.name }}</div>
                            </draggable>
                        </div>
                        <div class="allocation-list">
                            <span class="list-title">Allocation Rule Company</span>
                            <draggable 
                                class="right-list" 
                                animation="150" 
                                v-model="selectedOptions" 
                                group="allocationRule"
                            >
                                <div 
                                    v-for="(selectedOption, index) in selectedOptions" 
                                    :key="index"
                                    class="item"
                                    @dragstart="dragStartHandler(option)"
                                    @dragend="dragEndHandler"
                                >{{ selectedOption.name }}</div>
                            </draggable>
                        </div>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
                <div class="vx-col w-full">
                    <vs-button class="mr-3 mb-2" @click="handleSubmit" :disabled="localListAllocation.length > 0">Submit</vs-button>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>
import DataTable from "./dataTable.vue";
import draggable from 'vuedraggable';

export default {
    components: {
        DataTable,
        draggable,
    },
    props: {
        id: {
            type: Number
        },
        // listAllocation: {
        //     type: Array,
        //     default: () => [],
        // },
    },
    data() {
        return {
            title: "Allocation Rule Data",
            baseUrl: "/api/v1/setting/allocation-rule/",
            companyName: '',
            allocationRule: '',
            // localListAllocation: [...this.listAllocation],
            localListAllocation: [],
            selectedOptions: [],
            x: 0,
            y: 0,
            index: null,
        };
    },
    mounted() {
        if (this.id) {
            this.fetchData();
        }
    },
    methods: {
        handleClose() {
            window.scrollTo(0, 0);
            this.$emit("close");
        },
        handleSubmit() {
            if (this.localListAllocation.length === 0) {
                this.$vs.loading();
                this.updateData();
            } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Anda harus memindahkan semua Allocation ke sebelah kanan.",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            }
        },
        fetchData() {
            this.$vs.loading();
            this.$http
                .get(this.baseUrl + this.id)
                .then(resp => {
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        this.companyName = resp.data.Name;
                        this.localListAllocation = resp.data.option_allocation_rule;
                        if (resp.data.data_allocation_rule_company.length > 1) {
                            // this.localListAllocation = [];
                            resp.data.data_allocation_rule_company.forEach(item => {
                                this.selectedOptions.push({
                                    id: item.allocation_rule_id,
                                    name: item.allocation_rule_name,
                                });
                            });
                        }
                        this.$vs.loading.close();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                })
                .catch(error => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        paramData() {
            var allocation_rules_list = [];
            this.selectedOptions.forEach(arl => {
                allocation_rules_list.push(arl.id)
            });
            return {
                Name: this.companyName,
                allocation_rules_list: allocation_rules_list,
            };
        },
        updateData() {
            this.$http
                .put(this.baseUrl + this.id, this.paramData())
                .then(response => {
                    this.$vs.loading.close();
                    if (response.code == 200) {
                        this.handleClose();
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: this.title + " Updated",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: response.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                })
                .catch(error => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        dragStartHandler(option) {
            this.dragPreview = option.target.cloneNode(true);
            this.$refs["dragPreview"].appendChild(this.dragPreview);

            option.dataTransfer.setDragImage(new Image(), 0, 0);
        },
        dragEndHandler() {
            this.dragPreview.remove();
            this.dragPreview = null;
        },
        dragHandler(e) {
            this.x = e.pageX;
            this.y = e.pageY;
        },
    },
    watch: {
        Id(v) {
            if (v) {
                this.fetchData();
            } else {
                console.log("gatau apa ini")
            }
        }
    },
    computed: {
        dragStyle() {
            return {
                top: `${this.y}px`,
                left: `${this.x}px`
            };
        }
    },
};
</script>
<style>
.allocation-container {
    display: flex;
    justify-content: space-between;
}
.allocation-list {
    width: 48%;
    padding: 10px;
}
.list-title {
    font-weight: bold;
}
.left-list {
    width: 100%;
    padding: 10px;
}
.right-list {
    width: 100%;
    padding: 10px;
}
.item {
    display: block;
    width: 100%;
    border: 1px solid black;
    padding: 0.3rem;
    margin: 0.1rem;
    background: white;
}
.sortable-ghost {
  border: 1px dashed grey;
  font-size: 0;
  overflow: hidden;
}
.draggable div {
    margin: 5px;
    background-color: #e0e0e0;
    padding: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
}
.draggable div:active {
    background-color: #2b2bfe;
}
.drag-preview {
    position: absolute;
    transform: translateX(-50%) translateY(-50%) rotate(7deg) translateY(55%);
}
</style>